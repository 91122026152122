import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  child,
  get,
  onValue,
  off,
  update,
  remove,
} from "firebase/database";
import {
  getAuth,
  signInAnonymously,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase once
const app = initializeApp(config);
const auth = getAuth(app);

// Set up auth state listener
onAuthStateChanged(auth, (user) => {
  if (user) {
    console.log("User is signed in anonymously:", user.uid);
  } else {
    console.log("User is signed out");
    // Auto sign-in when no user is detected
    signInAnonymousUser();
  }
});

// Initialize with anonymous authentication
signInAnonymousUser();

let orderRef = undefined;
let deliveryOrderRef = undefined;

// Anonymous authentication functions
async function signInAnonymousUser() {
  try {
    const userCredential = await signInAnonymously(auth);
    console.log("Anonymous sign-in successful:", userCredential.user.uid);
    return userCredential.user;
  } catch (error) {
    console.error("Error signing in anonymously:", error.code, error.message);
    throw error;
  }
}

function getCurrentUser() {
  const user = auth.currentUser;
  console.log("Current user:", user ? user.uid : "No user");
  return user;
}

function listenToAuthChanges(callback) {
  console.log("Setting up auth state change listener");
  return onAuthStateChanged(auth, (user) => {
    console.log("Auth state changed:", user ? `User ${user.uid}` : "No user");
    callback(user);
  });
}

async function signOutUser() {
  try {
    await signOut(auth);
    console.log("User signed out successfully");
    return true;
  } catch (error) {
    console.error("Error signing out:", error);
    return false;
  }
}

async function getCommerceIdListIdentifier(dbRef, commerce, productListId) {
  if (productListId) return productListId;

  const shopList = await get(child(dbRef, `products/${commerce}`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val().idList;
      } else {
        console.error("1 - No shops data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });

  return shopList;
}

async function getCommerceIdCategories(commerce) {
  const dbRef = ref(getDatabase());
  const categories = await get(child(dbRef, `products/${commerce}`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val().categories;
      } else {
        console.error("2 - No shops data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });

  return categories;
}

async function getIdListProducts(dbRef, currentShopIdListIdentifier) {
  const primitiveProducts = await get(
    child(dbRef, `listProducts/${currentShopIdListIdentifier}/products`)
  )
    .then((snapshot) => {
      if (snapshot.exists()) {
        const mappedProducts = Object.entries(snapshot.val()).map(
          ([name, value]) => ({
            ...value,
            uuid: name,
          })
        );

        return mappedProducts;
      } else {
        console.error("3 - No shops data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });

  return primitiveProducts;
}
async function getIdListCombos(
  dbRef,
  currentShopIdListIdentifier,
  currentResultSet
) {
  await get(child(dbRef, `listProducts/${currentShopIdListIdentifier}/combos`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        const mappedCombos = Object.entries(snapshot.val()).map(
          ([name, value]) => ({
            ...value,
            type: "COMBO",
            uuid: name,
          })
        );

        if (mappedCombos) {
          currentResultSet = currentResultSet.concat(mappedCombos);
        }
        return currentResultSet;
      } else {
        return currentResultSet;
      }
    })
    .catch((error) => {
      console.error(error);
    });
  return currentResultSet;
}

async function getOriginName(dbRef, idList, originName, menuUUID) {
  if (originName !== undefined && originName !== null) {
    return originName;
  }

  return await get(child(dbRef, `listProducts/${idList}/menues`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val().filter((x) => x.uuid === menuUUID)[0].origins[0]
          .name;
      } else {
        console.error("5 - No shops data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

function filterAvailableProductsForOrigin(idListProducts, originName) {
  const availableProductsForOrigin = idListProducts.filter((product) => {
    return Boolean(
      product.name &&
        product.type &&
        product.origins &&
        product.origins[0] &&
        product.origins.find((origin) => {
          return (
            origin.price &&
            String(origin.name).toLowerCase() ===
              String(originName).toLowerCase()
          );
        })
    );
  });

  return availableProductsForOrigin;
}

async function getAvailableProductsOnce(
  commerceId,
  originName,
  menuUUID,
  productListId
) {
  const dbRef = ref(getDatabase());
  var idList = null;

  const availableProducts = await getCommerceIdListIdentifier(
    dbRef,
    commerceId,
    productListId
  )
    .then((response) => {
      idList = response;
      return getOriginName(dbRef, idList, originName, menuUUID);
    })
    .then((response) => {
      originName = response;
      return getIdListProducts(dbRef, idList);
    })
    .then((response) => {
      return getIdListCombos(dbRef, idList, response);
    })
    .then((response) => {
      return filterAvailableProductsForOrigin(response, originName);
    });

  return { availableProducts, originName };
}

function bindDeliveryReference({ commerceId, deliveryUuid }, callback) {
  deliveryOrderRef = ref(
    getDatabase(),
    `shops/${commerceId}/delivery/${deliveryUuid}`
  );

  onValue(deliveryOrderRef, (snapshot) => {
    const data = snapshot.val();
    callback(data ? data : {});
  });
}

function bindOrderReference({ commerceId, table }, callback) {
  orderRef = ref(getDatabase(), `shops/${commerceId}/borders/${table}`);

  onValue(orderRef, (snapshot) => {
    const data = snapshot.val();
    callback(data?.order ? data?.order : []);
  });
}

function unbindDeliveryReference({ commerceId, deliveryUuid }) {
  deliveryOrderRef = ref(
    getDatabase(),
    `shops/${commerceId}/delivery/${deliveryUuid}`
  );

  off(deliveryOrderRef);
}

function unbindOrderReference({ commerceId, table }) {
  orderRef = ref(getDatabase(), `shops/${commerceId}/borders/${table}`);

  off(orderRef);
}

function updateOrderReference(updates) {
  update(orderRef, updates);
}

function updateDeliveryReference(updates) {
  update(deliveryOrderRef, updates);
}

function removeDeliveryReference() {
  remove(deliveryOrderRef);
}

export {
  getAvailableProductsOnce,
  bindOrderReference,
  bindDeliveryReference,
  unbindOrderReference,
  unbindDeliveryReference,
  updateOrderReference,
  updateDeliveryReference,
  removeDeliveryReference,
  getCommerceIdCategories,
  // Auth functions
  signInAnonymousUser,
  getCurrentUser,
  listenToAuthChanges,
  signOutUser,
};
